import React from "react"

import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import Grid from "../components/Grid"
import Container from "../components/Container"
import Pagination from "../components/Pagination"
import SafeAreaWrapper from "../components/SafeAreaWrapper"

import ArticlePreview from "../components/ArticlePreview"

import Hero from "../components/Hero"
import { BlueBanner } from "../components/Banner"
import ConditionalWrapper from "../components/ConditionalWrapper"

const PressPage = ({
	location,
	pageContext,
	pageContext: { currentPage, limit, skip, numPages },
	data: { page, allPressCoverage, backgroundImage },
}) => {
	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero title="Press" backgroundImage={backgroundImage} translucent={true} />
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Grid className="mb-spacer-md">
						<div className="col-span-12 xl:col-span-10 xl:col-start-2">
							{allPressCoverage.group
								.slice(0)
								.reverse()
								.map(({ nodes }) => {
									return (
										<>
											<h3 className="display3 mb-30 mt-60 first:mt-0">
												{nodes[0].released_at_header}
											</h3>
											{nodes.map(
												({ headline, url, publication, released_at }) => (
													<ConditionalWrapper
														condition={url}
														wrapper={children => (
															<a
																href={`${url}`}
																target="_blank"
																rel="noreferrer"
																alt={`${headline}`}
																title={`${headline}`}>
																{children}
															</a>
														)}>
														<div className="bg-porcelain rounded-medium p-30 mb-20 group hover:cursor-pointer">
															<ArticlePreview
																className=""
																variant="snippet"
																category={publication}
																date={released_at}
																inlineDate={false}
																title={headline}
																hasArrow={true}
															/>
														</div>
													</ConditionalWrapper>
												),
											)}
										</>
									)
								})}
						</div>
					</Grid>

					<Container className="mb-spacer-lg flex flex-col items-center">
						<div className="flex flex-row justify-center">
							<Pagination urlPrefix="/press" pageContext={pageContext} />
						</div>
					</Container>

					<BlueBanner
						className="mt-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query ($skip: Int!, $limit: Int!) {
		page: strapiPressPage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}

		allPressCoverage(skip: $skip, limit: $limit, sort: { fields: released_at, order: DESC }) {
			group(field: released_at_header) {
				nodes {
					headline
					publication
					released_at(formatString: "MMMM DD, YYYY")
					released_at_header: released_at(formatString: "MMMM YYYY")
					url
				}
			}
		}

		backgroundImage: file(relativePath: { eq: "press.png" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}
	}
`

export default PressPage
